.mision {
    @include bgimg;
    @include media('lg') {
        padding: 4rem 0;
    }

    &__content {
        position: relative;
    }

    &__card {
        background-color: rgba($color-black, 0.5);
        padding: 2rem;
        color: $color-white;
        width: 100%;
        position: relative;
        @include media('lg') {
            right: -50%;
            width: 50%;
        } 
    }
}