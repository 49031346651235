.preventa {
    padding: 6rem 0;
    @include bgimg;
    @include media('lg') {
        padding: 4rem 0;
    }

    &__container {
        text-align: center;
        max-width: 450px;
    }

    &__logo {
        width: 250px;
        margin-bottom: 2rem;
    }

}
