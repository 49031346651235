.hero {
    padding: 6rem 0;
    @include media('md') {
        padding: 12rem 0;    
    }
    &--bgimg {
        @include bgimg;
    }

    //filter
    &__filter {
        top: 0;
        position: absolute;
        background-color: rgba(black, 0.25);
        width: 100%;
        height: 100%;
        
    }

    //hero contenidos
    &__container {

    }
    &__content {
        position: relative;
        margin: 0 auto;
        max-width: 750px;
        color: $color-white;
        text-align: center;
    }
}