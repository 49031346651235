.topbar {
    padding: .5rem 0;
    background-color: $color-yellow;
    display: none;
    @include media('lg') {
        display: block;
    }
    &__content {
        color: $color-white;
        text-align: center;
        a {
            @include link {
                color: $color-white;
                text-decoration: none;
            }
            @include hover {
                color: darken($color-white, 15%);
            }
        }
    }

    &__grid {
        @include reset-list;          
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        flex: 100%;
        &:first-child {
            font-weight: 500;
        }
        @include media('lg') {
            flex: 26%;
            &:first-child {
                flex: 45%;    
            }
            &:last-child {
                text-align: right;
            }
        }
    }
}