.text {
    &--title {
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: 300;
        strong {
            font-weight: 600;
        }
        @include media('lg') {
            font-size: 3.5rem;
        }
    }
    &--subtitle {
        font-size: 1.5rem;
        text-transform: uppercase;
        &-light {
            font-weight: 300;
        }
        @include media('lg') {
            font-size: 2.5rem;
        }
    }
    &--content {
        font-size: 1.1rem;
        font-weight: 300;
        @include media('lg') {
            font-size: 1.3rem;
        }
    }
}