.servicios {
    padding: 2rem 0; 

    &__header {
        padding: 2rem 0;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__col {
        width: 100%;
        @include media('lg') {
            width: 49%;
        }
    }
    &__img {
        width: 100%;
    }
}