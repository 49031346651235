.productos {
    padding: 3rem 0;
    background-color: $color-gray;

    &__title {
        text-align: center;
        margin-bottom: 2rem;
    }

    &__list {
        @include reset-list;
        text-align: center;
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item {
            width: 100%;
            margin-bottom: 2rem;
            @include media('lg') {
                width: 33%;
            }
        }
        &__title {
            display: block;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.1rem;
            @include media('lg') {
                font-size: 1.3rem;
            }
        }
        &__text {
            display: block;
            font-size: 1.1rem;
            @include media('lg') {
                font-size: 1.3rem;
            }
        }
        &__img {
            width: 150px;
        }
    }
    &__list--description {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item {
            width: 100%;
            margin-bottom: 2rem;
            @include media('lg') {
                width: 45%;
            }
        }
        &__title {
            display: block;
            text-transform: uppercase;
            font-weight: 600;
        }
        &__text {
            display: block;
            font-size: 1.1rem;
            @include media('lg') {
                font-size: 1.3rem;
            }
        }
    }
}