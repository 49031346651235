.footer {
    padding: 2rem 0 4rem;
    text-align: center;
    &__logo {
        width: 200px;
    }
    &__text {
        margin-bottom: .5rem;
        a {
            @include link {
                color: $color-yellow;
                text-decoration: none;
            }
            @include hover {
                color: ($color-yellow, 15%)
            }
        }
    }
}