.navBar {
    background-color: $color-black;
    padding: 1rem 0;
    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__col {
        &:first-child {
            text-align: left;
            width: 80%;
            @include media('lg') {
                width: 50%;
            }
        }
        &:nth-child(2) {
            width: 20%;
            @include media('lg') {
                display: none;
            }
        }
        &:last-child {
            width: 50%;
            position: relative;
            left: 40%;
            @include media('lg') {  
                position: relative;
                margin-top: 0;
                left: 0;
            }
        }
    }
    &__logo {
        width: 120px;
        @include media('lg') {
            width: 200px;
        }
    }
    &__nav {
        @include reset-list;  
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        max-height: 500px;
        overflow: hidden;
        transition: max-height ease-in-out 0.35s;
        &.visible  {
            max-height: 0;
            @include media('lg') {
                max-height: 500px;
            }
        }
        @include media('lg') {
            flex-direction: row;
            margin-top: 0;
        }
        &__item {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
            a {
                @include link {
                    color: $color-white;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                @include hover {
                    color: darken($color-white, 15%);
                }
            }
        }
    }
    &__toggle {
        background-color: $color-white;
        border: none; 
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__icon {
            margin-bottom: 0.35rem;
            width: 1.8rem; 
            height: 3px; 
            background-color: $color-black;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}