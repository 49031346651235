@mixin reset-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}
  
@mixin link() {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        transition: all .3s ease;
        @content;
    }
}

@mixin hover() {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin bgimg() {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

$breakpoints: (
    'xs': 414px,
    'sm': 576px,
    'md': 768px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1400px
) !default;

@mixin media($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}