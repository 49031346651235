.contact {
    background-color: $color-black;
    color: $color-white;

    padding: 2rem 0 4rem;
    &__header {
        padding: 2rem 0; 
        text-align: center;
    }
    &__content {
        text-align: center;
        a {
            @include link {
                color: $color-white;
                font-size: 1.2rem;
                @include media('lg') {
                    font-size: 1.5rem;
                }
            }
            @include hover {
                color: darken($color-white, 15%)
            } 
        }
    }
    &__text {
        display: block;
    }
}